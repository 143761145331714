// Libs
import React from 'react';

//  Services
import API from 'API';

// Components
import Loader from 'components/common/Loader';

// Images
import logoImage from 'images/logo.png';

//-------------------------------------------------------------------------------------------------------------------

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    async submit() {
        const {
            username,
            password
        } = this.state;
        this.setState({ isLoading: true });
        try {
            await API.call('account/log-in', {
                username, password
            });
            this.props.onLogIn();
        } catch (error) {
            this.setState({
                isLoading: false,
                error
            });
        }
    }

    render() {
        return (<>

            <form className="panel login-panel" onSubmit={e => { e.preventDefault(); this.submit() }}>

                <img src={logoImage} />

                {this.renderInner()}

            </form>

        </>);
    }

    renderInner() {
        const {
            username,
            password,
            error,
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        
        return (<>

            {error &&
                <div className="alert alert-danger">
                    {error}
                </div>
            }

            <div className="form-group">
                <label>Username</label>
                <input
                    type="text"
                    className="form-control"
                    value={username || ''}
                    onChange={e => this.setState({ username: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label>Password</label>
                <input
                    type="password"
                    className="form-control"
                    value={password || ''}
                    onChange={e => this.setState({ password: e.target.value })}
                />
            </div>

            <button className="btn btn-primary" type="submit">
                Log in
            </button>

        </>);
    }

}

export default Login;