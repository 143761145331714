import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

import API from 'API';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import SearchBox from 'components/common/SearchBox';

//-----------------------------------------------------------------

class DedupeCustomerModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = { };
        
        this.fields = [
            { name: 'Title', code: 'Customer.Title', getValue: (c) => c.title },
            { name: 'First Name', code: 'Customer.FirstName', getValue: (c) => c.firstName },
            { name: 'Last Name', code: 'Customer.LastName', getValue: (c) => c.lastName },
            { name: 'Type', code: 'Customer.Type', getValue: (c) => c.type },
            { name: 'Source', code: 'Customer.Source', getValue: (c) => c.source },
            { name: 'Phone Number', code: 'Customer.LandlineTel', getValue: (c) => c.landlineTel },
            { name: 'Mobile Number', code: 'Customer.MobileTel', getValue: (c) => c.mobileTel },
            { name: 'Email Address', code: 'Customer.EmailAddress', getValue: (c) => c.emailAddress },
            { name: 'Customer Notes', code: 'Customer.Notes', canPickBoth: true, getValue: (c) => c.notes },

            { name: 'Postcode', code: 'Property.Postcode', getValue: (c) => c.property.address.postcode },
            { name: 'Address 1', code: 'Property.Address1', getValue: (c) => c.property.address.line1 },
            { name: 'Address 2', code: 'Property.Address2', getValue: (c) => c.property.address.line2 },
            { name: 'Town', code: 'Property.Town', getValue: (c) => c.property.address.town },
            { name: 'Price Scheme', code: 'Property.PriceScheme', getValue: (c) => c.property.priceSchemeName },
            { name: 'What.Three.Words', code: 'Property.WhatThreeWords', getValue: (c) => c.property.whatThreeWords },
            { name: 'Property Notes', code: 'Property.Notes', canPickBoth: true, getValue: (c) => c.property.notes },
            { name: 'Directions', code: 'Property.Directions', canPickBoth: true, getValue: (c) => c.property.directions },
            { name: 'Access Details', code: 'Property.Access', canPickBoth: true, getValue: (c) => c.property.access },

            {
                name: 'Rebook Months',
                code: 'Property.RebookMonths',
                canPickBoth: true,
                getValue: (c) => Object.keys(c.property.rebookMonths).map(m => {
                    const date = new Date();
                    date.setMonth(m - 1);
                    return date.toLocaleString([], { month: 'short' })
                }).join(', ')
            }
        ];
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                originalCustomerID: opt.customerID,
                originalCustomer: null,
                otherCustomer: null,
                fields: {}
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        const { originalCustomerID, otherCustomerID } = this.state;
        this.setState({ isLoading: true });
        const { originalCustomer, otherCustomer } = await API.call('dedupe/get-customer-info', {
            originalCustomerID,
            otherCustomerID
        });
        const fields = {};
        for (let i = 0; i < this.fields.length; i++) {
            const field = this.fields[i];
            const takeOriginal = !!field.getValue(originalCustomer);
            fields[field.code] = {
                takeOriginal,
                takeOther: otherCustomer && !takeOriginal && !!field.getValue(otherCustomer)
            };
        }
        this.setState({
            originalCustomer,
            otherCustomer,
            fields,
            isLoading: false
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async search(query) {
        this.setState({ query });
        const nonce = `${Math.random()}`;
        const response = await API.call('search/customer-property', {
            query,
            nonce,
            ignoreCustomerID: this.state.originalCustomerID
        });
        if (response.nonce == nonce) {
            this.setState({
                customers: response.results
            });
        }
    }

    selectOtherCustomer(c) {
        this.setState({
            otherCustomerID: c.id
        }, () => {
            this.load();
        });
    }

    updateField(fieldCode, values) {
        const fields = { ...this.state.fields };
        if (!fields[fieldCode]) {
            fields[fieldCode] = {};
        }
        const field = this.fields.find(f => f.code == fieldCode);
        if (!field.canPickBoth) {
            if (typeof (values.takeOriginal) != 'undefined') {
                values.takeOther = !values.takeOriginal;
            } else if (typeof (values.takeOther) != 'undefined') {
                values.takeOriginal = !values.takeOther;
            }
        }
        for (let fieldName in values) {
            const value = values[fieldName];
            fields[fieldCode][fieldName] = value;
        }
        this.setState({ fields });
    }

    async confirmDedupe() {
        const confirm = window.confirm('Are you sure you want to de-duplicate these customers?');
        if (confirm) {
            const { fields, originalCustomerID, otherCustomerID } = this.state;
            this.setState({ isLoading: true });
            await API.call('dedupe/run-customer', {
                originalCustomerID,
                otherCustomerID,
                fields
            });
            this.setState({ isLoading: false });
            this.close();
            this.resolve();
        }
    }

    render() {
        const { isOpen, isLoading, otherCustomer } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="dedupe-customer-modal" backdrop="static">
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    {/*<Modal.Header>
                        <button type="button" className="btn btn-secondary ms-auto" onClick={() => this.close()}>
                            Close
                        </button>
                    </Modal.Header>*/}
                    <Modal.Body>
                        
                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        {isLoading ? <Loader /> : <>
                            <button type="button" className="btn btn-secondary me-auto" onClick={() => this.close()}>
                                Cancel
                            </button>
                            {otherCustomer &&
                                <button type="button" className="btn btn-primary ms-auto" onClick={() => this.confirmDedupe()}>
                                    De-duplicate
                                </button>
                            }
                        </>}
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            originalCustomer,
            otherCustomer
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="row">

                <div className={otherCustomer ? 'col-md-12' : 'col-md-4'}>

                    {this.renderCustomerTable()}

                </div>

                {!otherCustomer &&
                    <div className="col-md-8">

                        <SearchBox
                            className="form-control"
                            placeholder="Search..."
                            minLength={2}
                            autoFocus={true}
                            searchDelay={250}
                            search={(query) => this.search(query)}
                            clear={() => this.setState({ customers: [] })}
                        />
                        {this.renderSearchTable()}

                    </div>
                }

            </div>

        </>);
    }

    renderSearchTable() {
        const {
            isLoading,
            customers,
            query
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        // Flatten list
        let customersFlattened;
        if (customers && customers.length > 0) {
            customersFlattened = [];
            customers.forEach(c => {
                if (!ReferenceHelpers.isIndividual(c.customerType)) {
                    customersFlattened.push({
                        ...c
                    });
                }
                c.properties.forEach(p => {
                    const customerAndProperty = {
                        ...c,
                        isProperty: true,
                        property: { ...p }
                    };
                    customersFlattened.push(customerAndProperty);
                });
            });
        }

        return (<>
            <SuperTable
                className="customers-table table clickable table-bordered clickable mb-0"
                rows={customersFlattened || []}
                keyAccessor={c => `${c.id}-${c.property ? c.property.id : 'company'}`}
                cols={{
                    name: {
                        label: 'Name',
                        getValue: (colInfo, customer) => {
                            const lines = [
                                customer.name,
                                customer.companyName ? 'Company: ' + customer.companyName : ''
                            ];
                            return lines.filter(l => !!l).map((l, index) =>
                                <div key={index}>{TextHelpers.formatHighlightedText(l)}</div>
                            );
                        }
                    },
                    address: {
                        label: 'Property',
                        getValue: (colInfo, c) => {
                            if (c.property) {
                                return TextHelpers.formatHighlightedText(c.property.address);
                            } else {
                                return 'Company';
                            }
                        }
                    },
                    lastApptDate: {
                        label: 'Last Appt',
                        type: 'date',
                        getValue: (colInfo, c) => {
                            if (c.property && c.property.lastApptDate) {
                                return moment(c.property.lastApptDate).format('DD/MM/YYYY');
                            }
                        }
                    },
                    nextApptDate: {
                        label: 'Next Appt',
                        type: 'date',
                        getValue: (colInfo, c) => (c.property && c.property.nextApptDate ? moment(c.property.nextApptDate).format('DD/MM/YYYY') : '')
                    }
                }}
                onClick={(c, e) => this.selectOtherCustomer(c)}
                emptyText={query && customersFlattened && customersFlattened.length == 0 ?
                    'No matching customers or properties' : 'Please enter a search query'}
            />
        </>);
    }

    renderCustomerTable() {
        const { originalCustomer, otherCustomer, fields } = this.state;
        if (!originalCustomer) return null;
        
        return (
            <table className="table table-bordered customer-info-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>This customer</th>
                        {otherCustomer &&
                            <th>Other customer</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {this.fields.map((field, index) => {
                        const originalCustomerVal = originalCustomer ? field.getValue(originalCustomer) : null;
                        const otherCustomerVal = otherCustomer ? field.getValue(otherCustomer) : null;
                        return (
                            <tr key={index}>
                                <th>{field.name}</th>
                                <td
                                    onClick={() => this.updateField(field.code, { takeOriginal: !fields[field.code].takeOriginal })}
                                >
                                    {otherCustomer && !!originalCustomerVal && <>
                                        <input
                                            type={field.canPickBoth ? 'checkbox' : 'radio'}
                                            checked={fields[field.code].takeOriginal}
                                            onChange={() => null}
                                        />{' '}
                                    </>}
                                    {originalCustomerVal}
                                </td>
                                {otherCustomer &&
                                    <td
                                        onClick={() => this.updateField(field.code, { takeOther: !fields[field.code].takeOther })}
                                    >
                                        {otherCustomer && !!otherCustomerVal && <>
                                            <input
                                                type={field.canPickBoth ? 'checkbox' : 'radio'}
                                                checked={fields[field.code].takeOther}
                                                onChange={() => null}
                                            />{' '}
                                        </>}
                                        {otherCustomerVal}
                                    </td>
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

}

export default DedupeCustomerModal;