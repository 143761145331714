import React from 'react';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';

//-----------------------------------------------------------------

class Report extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            optionValues: {},
            sorting: {}
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { id } = this.props;
        this.setState({ isLoading: true });

        // Load report
        const report = await API.call('report/get/' + id);

        // Set up options
        const optionValues = {};
        const formHelperOptions = {
            fields: {},
            getValue: (fieldName) => this.state.optionValues[fieldName],
            setValue: (fieldName, value) => this.updateOptionValue(fieldName, value)
        };
        this.formHelper = new FormHelper(formHelperOptions);
        report.options.forEach(o => {
            let type = '';
            switch (o.dataType) {
                case 'Text': type = 'text'; break;
                case 'SingleSelect': type = 'single-select'; break;
                case 'ButtonGroup': type = 'button-group'; break;
                case 'Bool': type = 'checkbox'; break;
                case 'Date': type = 'date'; break;
                default: type = o.dataType; break;
            }
            optionValues[o.id] = o.defaultValue;
            formHelperOptions.fields[o.id] = {
                type: type,
                label: o.label,
                blankText: o.blankText,
                getOptions: () => o.optionValues
            };
        });

        this.props.fns.setTitle(report.name);

        this.setState({
            isLoading: false,
            report,
            optionValues
        });
    }

    updateOptionValue(name, value) {
        const optionValues = { ...this.state.optionValues };
        optionValues[name] = value;
        this.setState({ optionValues });
    }

    async run() {
        const { id } = this.props;
        const { optionValues } = this.state;
        this.setState({ isLoading: true });
        try {
            // Run
            const results = await API.call('report/run', {
                id,
                optionValues
            });

            // Set up sorting
            const sorting = { ...this.state.sorting};   
            results.map((section, index) => {
                if (!sorting[index]) {
                    sorting[index] = {
                        sortBy: section.defaultSortBy,
                        sortDesc: section.defaultSortDesc
                    };
                }
            });

            // Update UI
            this.setState({
                isLoading: false,
                results,
                sorting
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                results: null,
                error
            });
        }
    }

    async exportExcel() {
        const { id } = this.props;
        const { optionValues } = this.state;
        this.setState({ isLoading: true });
        await API.call('report/export-excel', {
            id,
            optionValues,
            isDownload: true
        });
        this.setState({ isLoading: false });
    }

    changeSort(index, sortBy, sortDesc) {
        const { sorting } = this.state;
        const sectionSorting = { ...sorting[index] } 
        sectionSorting.sortBy = sortBy;
        sectionSorting.sortDesc = sortDesc;
        sorting[index] = sectionSorting;
        this.setState({ sorting });
    }

    //----------------------------------------------------------------------

    render() {
        const {
            isLoading,
            error,
            results
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel">
                
                {this.renderOptions()}

                <div className="buttons ms-auto">

                    <button type="button" className="btn btn-secondary me-3" onClick={() => this.exportExcel()}>
                        Export
                    </button>

                    <button type="button" className="btn btn-primary" onClick={() => this.run()}>
                        Run
                    </button>

                </div>

            </section>

            {!!error &&
                <section>
                    <div className="alert alert-danger">
                        {error}
                    </div>
                </section>
            }

            {/* Results */}
            {results && results.map((section, index) =>
                <React.Fragment key={index}>
                    {this.renderSection(section, index)}
                </React.Fragment>
            )}
        </>);
    }

    renderOptions() {
        const { report } = this.state;
        const fieldIDs = report.options.map((o, index) => o.id);
        return fieldIDs.map(id => 
            <div className="control-panel-component" key={id}>
                {this.formHelper.render(id)}
            </div>
        );
    }

    renderSection(section, index) {
        const { sorting } = this.state;

        // Add total row
        const rows = [...section.rows];
        if (section.columns.some(c => c.showTotal)) {
            const firstColID = section.columns[0].id;
            const row = {
                [firstColID]: 'Total',
                _className: 'total-row'
            };
            for (let i = 0; i < section.columns.length; i++) {
                const col = section.columns[i];
                if (col.showTotal) {
                    let total = 0;
                    for (let j = 0; j < rows.length; j++) {
                        const row = rows[j];
                        total += (Number(row[col.id]) || 0);
                    }
                    row[col.id] = total;
                }
            }
            rows.push(row);
        }

        return (
            <section className={section.className || ''}>

                {section.title &&
                    <h2>{section.title}</h2>
                }
                <SuperTable
                    keyAccessor={(row, index) => index}
                    className="report-table table table-bordered mb-0 clickable sticky-header"
                    rows={rows}
                    cols={section.colsDict}
                    sortBy={sorting[index].sortBy}
                    sortDesc={sorting[index].sortDesc}
                    onChangeSort={(sortBy, sortDesc) => this.changeSort(index, sortBy, sortDesc)}
                    rowPropsAccessor={(row, index) => {
                        let props = {
                            style: {}
                        };
                        if (row._className) {
                            props.className = row._className;
                        }
                        if (row._bold) {
                            props.style.fontWeight = 'bold';
                        }
                        return props;
                    }}
                    cellPropsAccessor={(colInfo, row, rowIndex, colIndex) => {
                        let props = {
                            style: {}
                        };
                        if (row) {
                            if (row._indent > 0 && colIndex == 0) {
                                props.style.paddingLeft = row._indent;
                            }
                            else if (colInfo.col.indent > 0) {
                                props.style.paddingLeft = colInfo.col.indent;
                            }
                        }
                        if (colInfo.col.width) {
                            props.style.width = colInfo.col.width;
                        }
                        return props;
                    }}
                    groupingAccessor={(colInfo, row, index) => row._grouping}
                    emptyText="No results"
                />
            </section>
        );
    }
}

export default Report;