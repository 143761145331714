import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import Print from 'Print';

// Components
import Loader from 'components/common/Loader';
import EditProperty from 'components/customer/EditProperty';
import CustomerCommList from 'components/customer/CustomerCommList';
import ApptList from 'components/customer/ApptList';
import MovePropertyModal from 'components/customer/MovePropertyModal';
import PreviewEmailModal from 'components/outgoing/PreviewEmailModal';

//-----------------------------------------------------------------

class EditPropertyModal extends React.Component {

    constructor(props) {
        super(props);

        this.previewEmailModalRef = React.createRef();
        this.movePropertyModalRef = React.createRef();

        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: opt.id,
                customerID: opt.customerID
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const {
            id,
            customerID
        } = this.state;

        // Load property
        let property;
        if (id) {
            property = await API.call('property/get/' + id);
        } else if (customerID) {
            property = {
                customerID,
                isOccupied: true,
                cookers: []
            };
        }

        // Update
        this.setState({
            property,
            isLoading: false
        });
    }

    async save() {
        this.setState({ isLoading: true });
        const id = await API.call('property/save', this.state.property);
        this.setState({ isLoading: false });
        return id;
    }
    
    async saveAndClose() {
        await this.save();
        this.close();
        this.resolve();
    }

    async saveAndAddAppt() {
        const id = await this.save();
        this.close();
        const { property } = this.state;
        this.props.history.push(`/day-sheet?customerID=${property.customerID}&propertyID=${id}`);
    }

    updateFields(fields, callback) {
        const property = { ...this.state.property };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            property[fieldName] = value;
            //this.changedFields[fieldName] = true;
        }
        this.setState({ property }, callback);
    }

    printLatestCertificate(isInline) {
        Print.url('/api/appt/get-certificate-pdf/' + this.state.property.latestApptID, {
            isInline
        });
    }

    async confirmResendCompletedNotification() {
        const id = this.state.property.latestApptID;
        const send = await this.previewEmailModalRef.current.open({
            canSend: true,
            email: {
                type: 'ApptCompleted',
                apptID: id
            }
        });
        if (send) {
            await API.call('appt/trigger-notification', {
                apptID: this.state.property.latestApptID,
                type: 'ApptCompleted'
            });
        }
    }

    async showMoveToOtherCustomerModal() {
        await this.movePropertyModalRef.current.open({
            id: this.state.property.id
        });
        this.close();
        this.resolve();
    }

    render() {
        const { 
            isOpen,
            isLoading,
            property
        } = this.state;
        const { history, cameFrom } = this.props;

        if (!isOpen) {
            return null;
        }
        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            <Modal show onHide={() => this.close()} className="edit-property-modal" backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.saveAndClose(); e.preventDefault(); e.stopPropagation(); }}>
                        <Modal.Header>
                            <Modal.Title>
                                {property.name || 'New Property'}
                            </Modal.Title>
                            <div className="modal-header-buttons">
                                {this.renderButtons(true)}
                            </div>
                        </Modal.Header>
                        <Modal.Body>

                            <EditProperty
                                showCompanyFields
                                property={property || {}}
                                updateFields={(fields, callback) => this.updateFields(fields, callback)}
                                onSwitch={this.props.onSwitch}
                                onReload={() => this.load()}
                            />

                            {property.id && <>
                                <div className="mb-3">
                                    <CustomerCommList
                                        customerID={property.customerID}
                                        propertyID={property.id}
                                        defaultType={cameFrom == 'rebooks' ? 'Call' : 'Note'}
                                        onAdd={() => {
                                            if (cameFrom == 'rebooks' && history) {
                                                history.push('/rebooks');
                                            }
                                        }}
                                    />
                                </div>

                                <ApptList
                                    {...this.props}
                                    customerID={property.customerID}
                                    propertyID={property.id}
                                />
                            </>}

                        </Modal.Body>
                        <Modal.Footer>
                            {this.renderButtons(false)}
                        </Modal.Footer>
                    </form>
                }
            </Modal>

            <PreviewEmailModal ref={this.previewEmailModalRef} />
            <MovePropertyModal ref={this.movePropertyModalRef} />

        </>);
    }

    renderButtons(isTop) {
        const { property } = this.state;

        return (<>

            <button type="button" className="btn btn-secondary me-auto" onClick={() => this.close()}>
                Cancel
            </button>

            <div className={`btn-group ${isTop ? 'dropdown' : 'dropup'}`}>
                <button type="button" className="btn btn-tertiary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    More...
                </button>
                <ul className="dropdown-menu">
                    {!!property.latestApptID && <>
                        <li>
                            <a className="dropdown-item" href="#" onClick={e => {
                                e.preventDefault();
                                this.printLatestCertificate(true);
                            }}>
                                <span className="fa-solid fa-certificate" />{' '}Print latest certificate
                                {property.latestApptDate && <>
                                    {' '}({moment(property.latestApptDate).format('DD/MM/YYYY')})
                                </>}
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="#" onClick={e => {
                                e.preventDefault();
                                this.printLatestCertificate(false);
                            }}>
                                <span className="fa-solid fa-certificate" />{' '}Download latest certificate
                                {property.latestApptDate && <>
                                    {' '}({moment(property.latestApptDate).format('DD/MM/YYYY')})
                                </>}
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="#" onClick={e => {
                                e.preventDefault();
                                this.confirmResendCompletedNotification();
                            }}>
                                <span className="fa-solid fa-certificate" />{' '}
                                Email certificate
                            </a>
                        </li>
                    </>}
                    {!!property.id &&
                        <li>
                            <a className="dropdown-item" href="#" onClick={e => {
                                e.preventDefault();
                                this.showMoveToOtherCustomerModal();
                            }}>
                                <span className="fa-solid fa-right-to-bracket" />{' '}Move to different customer
                            </a>
                        </li>
                    }
                </ul>
            </div>

            <button type="button" className="btn btn-primary" onClick={() => this.saveAndAddAppt()}>
                Add Appt
            </button>
            <button type="submit" className="btn btn-primary">
                Save & Close
            </button>

        </>);
    }
}

export default EditPropertyModal;