import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditCustomerCommModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                date: {
                    label: 'Date',
                    type: 'date'
                },
                time: {
                    label: 'Time',
                    type: 'time'
                },
                type: {
                    label: 'Communication type',
                    type: 'single-select',
                    getOptions: () => ReferenceHelpers.CustomerCommTypes.map(cct => ({
                        value: cct.id,
                        text: cct.name
                    }))
                },
                result: {
                    label: 'Result',
                    type: 'single-select',
                    getOptions: () => ReferenceHelpers.CustomerCommResults.map(ccr => ({
                        value: ccr.id,
                        text: ccr.name
                    }))
                },
                description: {
                    label: 'Notes',
                    type: 'expanding-text',
                    rows: 2,
                    autoFocus: true
                }
            },
            getValue: (fieldName) => this.state.customerComm[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isChanged: false,
                isLoading: true,
                id: opt.id,
                customerID: opt.customerID,
                propertyID: opt.propertyID,
                rebookID: opt.rebookID,
                type: opt.type
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const { id, customerID, propertyID, rebookID, type } = this.state;
        this.setState({ isLoading: true });

        // Load or create customer comm
        let customerComm;
        if (id) {
            customerComm = await API.call('customer-comm/get/' + id);
        } else {
            customerComm = {
                rebookID,
                customerID,
                propertyID,
                date: moment().startOf('day').toDate(),
                time: moment().format('HH:mm'),
                type: (type || 'Call'),
                result: ReferenceHelpers.CustomerCommResults[0].id
            };
        }

        // Load next rebook for the property
        if (!customerComm.rebook)
        {
            if (rebookID) {
                customerComm.rebook = await API.call('rebook/get/' + rebookID);
            }
            else if (customerComm.propertyID) {
                customerComm.rebook = await API.call('rebook/get-next-for-property/' + customerComm.propertyID);
            }
        }
        
        this.setState({
            customerComm,
            isLoading: false
        });
    }

    save(customerComm) {
        return new Promise(async (resolve, reject) => {
            this.setState({ isLoading: true });
            await API.call('customer-comm/save', customerComm);
            this.setState({ isLoading: false });
            resolve();
        });
    }

    updateFields(fields) {
        const customerComm = { ...this.state.customerComm };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            customerComm[fieldName] = value;
        }
        this.setState({
            customerComm
        });
    }

    async saveAndClose() {
        const { customerComm } = this.state;

        if (this.state.id || customerComm.type != 'Note' || customerComm.description) {
            //(
            //    (customerComm.type == 'Call' && fieldName == 'result' && value == 'AppointmentBooked') ||
            //    (fieldName == 'type' && value == 'Card')
            //)

            // Offer to close the rebook
            if (customerComm.rebook && customerComm.type != 'Note') {

                const date = new Date(customerComm.rebook.date);
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];

                const formattedDate = monthNames[date.getMonth()] + " " + date.getFullYear();
                const confirm = window.confirm('Do you want to close the rebook for ' + formattedDate + '?');

                if (confirm) {
                    customerComm.closeRebook = true;
                }
            }

            await this.save(customerComm);
        }

        this.close();
        this.resolve();
    }

    render() {
        const { 
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-customer-comm-modal" backdrop="static" keyboard={false}>
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Body>

                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary ms-auto">
                            Save & Close
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const { isLoading, customerComm } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        
        return (<>

            <div className="row">

                <div className="col-md-6">

                    {this.form.render('date')}

                </div>

                <div className="col-md-6">

                    {this.form.render('time')}

                </div>

            </div>

            {this.form.render([
                'type',
                (customerComm.type == 'Call' && 'result'),
                'description',
            ])}

        </>);
    }
}

export default EditCustomerCommModal;