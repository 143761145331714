import moment from 'moment';

export default class DateHelpers {

    static combineDateAndTime(date, time) {
        date = moment(date).format('YYYY-MM-DD');
        return moment(`${date}T${time}`).toDate();
    }

    static stripSeconds(time) {
        if (!time) return time;
        const timeParts = time.split(':');
        const result = timeParts.splice(0, 2);
        return result.join(':');
    }

    //static stripTime(dateTime) {
    //    if (!dateTime) return null;
    //    const dateTimeMoment = moment(dateTime);
    //    const dateStr = dateTimeMoment.format('YYYY-MM-DD') + 'T00:00Z';
    //    //const offset = dateTimeMoment.toDate().getTimezoneOffset();
    //    const dateMoment = moment(dateStr);
    //    //dateMoment.add(-offset, 'minutes');
    //    return dateMoment.toDate();
    //}

    //static makeTime(datePart, timePart) {
    //    const result = moment(moment(datePart).format('YYYY-MM-DD') + ' ' + timePart).toDate();
    //    return result;
    //}

    //static dateTimeToNumMins(dateTime) {
    //    const dateTimeMoment = moment(dateTime);
    //    const numMins = parseInt(dateTimeMoment.format('mm'));
    //    const numHours = parseInt(dateTimeMoment.format('HH'));
    //    return numHours * 60 + numMins;
    //}

    static parseTimeToNumMins(time) {
        if (!time) return 0;
        const timeParts = time.split(':');
        return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
    }

    static sortApptList(appts) {
        appts.sort((a, b) => {
            const minsA = DateHelpers.parseTimeToNumMins(a.time);
            const minsB = DateHelpers.parseTimeToNumMins(b.time);
            return minsA - minsB;
        });
    }

    //static numMinsToTime(numMins) {
    //    const numHours = Math.floor(numMins / 60);
    //    numMins -= numHours * 60;
    //    return (numHours < 10 ? '0' : '') + numHours + ':' + (numMins < 10 ? '0' : '') + numMins;
    //}

    //static listTimes(interval, earliest) {
    //    const results = [];
    //    interval = parseInt(interval);
    //    if (!interval || interval <= 0) return results;
    //    let earliestMins = 0;
    //    if (earliest) {
    //        earliestMins = DateHelpers.parseTimeToNumMins(earliest);
    //    }
    //    let numHours = 0, numMins = 0;
    //    let totalNumMins = 0;
    //    while (numHours < 24) {
    //        const time = (numHours < 10 ? '0' : '') + numHours + ':' + (numMins < 10 ? '0' : '') + numMins;
    //        if (totalNumMins >= earliestMins) {
    //            results.push({
    //                value: time,
    //                text: time
    //            });
    //        }
    //        totalNumMins += interval;
    //        numMins += interval;
    //        while (numMins >= 60) {
    //            numMins -= 60;
    //            numHours++;
    //        }
    //    }
    //    return results;
    //}

    //static findNearestTime(oldTime, newTimes) {
    //    oldTime = DateHelpers.parseTimeToNumMins(oldTime);
    //    let lastNewTime = null;
    //    for (var i = 0; i < newTimes.length; i++) {
    //        const newTime = newTimes[i];
    //        const newTimeMins = DateHelpers.parseTimeToNumMins(newTime.value);
    //        if (newTimeMins == oldTime) {
    //            return newTime.value;
    //        } else if (newTimeMins > oldTime) {
    //            return lastNewTime;
    //        }
    //        lastNewTime = newTime.value;
    //    }
    //}

    //static removeSeconds(timeSpan) {
    //    if (!timeSpan) return '';
    //    const parts = timeSpan.split(':');
    //    parts.splice(2);
    //    return parts.join(':');
    //}

    //static convertTimeSpanToDate(timeSpan) {
    //    const parts = timeSpan.split(':');
    //    return new Date(2000, 0, 1, Number(parts[0]), Number(parts[1]), 0);
    //}

}
