import React from 'react';
import moment from 'moment';

// Services
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import EditApptModal from 'components/appt/EditApptModal';
import DatePicker from 'components/common/DatePicker';

//-----------------------------------------------------------------

class ListAppts extends React.Component {

    constructor(props) {
        super(props);

        this.editApptModalRef = React.createRef();

        this.state = {
            appts: [],
            pages: [
                { id: 'completed-awaiting-cert', label: 'Completed, awaiting Certificate' },
                { id: 'open-bookings', label: 'Open bookings' },
                { id: 'completed-appts', label: 'Completed appointments' },
                { id: 'all-open-appts', label: 'All open appointments' },
                { id: 'all-bookings', label: 'All bookings' },
                { id: 'all-appts', label: 'All appointments' },
                { id: 'awaiting-payment', label: 'Awaiting Payment' }//,
                //{ id: 'send-invoice', label: 'Invoices' }
            ],
            filters: {
                dateFrom: moment().startOf('month').toDate(),
                dateTo: moment().startOf('date').toDate()
            },
            page: 'completed-awaiting-cert'
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Appointments');
        this.load();
    }

    async load() {
        this.setState({ isLoading: true });
        
        // Load and update
        const listParam = this.getListParam();
        const appts = await API.call('appt/list', listParam);
        this.setState({
            isLoading: false,
            appts
        });
    }

    getListParam() {
        const { filters, page } = this.state;
        const listParam = {
        };
        switch (page) {
            case 'completed-awaiting-cert':
                listParam.status = 'completed';
                listParam.isAwaitingCert = true;
                listParam.hasCustomer = true;
                break;
            case 'open-bookings':
                listParam.status = 'open';
                listParam.hasCustomer = true;
                break;
            case 'completed-appts':
                listParam.status = 'completed';
                break;
            case 'all-open-appts':
                listParam.status = 'open';
                break;
            case 'all-bookings':
                listParam.hasCustomer = true;
                break;
            case 'all-appts':
                break;
            case 'awaiting-payment':
                listParam.status = 'completed';
                listParam.isAwaitingPayment = true;
                break;
            case 'send-invoice':
                listParam.paymentType = 'SendInvoice';
                break;
        }
        if (this.hasDatePicker(page)) {
            listParam.dateFrom = filters.dateFrom ? moment(filters.dateFrom).format('YYYY-MM-DD') : null;
            listParam.dateTo = filters.dateTo ? moment(filters.dateTo).format('YYYY-MM-DD') : null;
        }
        return listParam;
    }

    hasDatePicker(page) {
        return [
            //'completed-awaiting-cert',
            'open-bookings',
            'completed-appts',
            'all-open-appts',
            'all-bookings',
            'send-invoice'
        ].indexOf(page) != -1;
    }

    async open(id) {
        await this.editApptModalRef.current.open({
            id
        });
        this.load();
    }

    async add(customerID, date) {
        await this.editApptModalRef.current.open({
            customerID,
            date
        });
        this.load();
    }

    updateFilters(values) {
        const filters = { ...this.state.filters };
        for (let fieldName in values) {
            const value = values[fieldName];
            filters[fieldName] = value;
        }
        this.setState({
            filters,
            isLoading: true
        }, () => {
            this.load();
        });
    }

    setPage(page) {
        this.setState({
            page,
            isLoading: true
        }, () => {
            this.load();
        });
    }

    async confirmHide(id) {
        const confirm = window.confirm('Hide this appointment from the Awaiting Payment list?');
        if (confirm) {
            await API.call('appt/hide-from-awaiting-payment/' + id);
            this.load();
        }
    }

    //----------------------------------------------------------------------

    render() {
        const {
            pages,
            filters,
            appts,
            page
        } = this.state;

        return (<>
            <section className="control-panel list-appts-page">
                <select
                    className="form-control"
                    onChange={e => this.setPage(e.target.value)}
                >
                    {pages.map(p =>
                        <option key={p.id} value={p.id}>{p.label}</option>
                    )}
                </select>
                {appts &&
                    <div className="control-panel-component ms-3">
                        Number of items: {appts.length}
                    </div>
                }

                {this.hasDatePicker(page) &&
                    <div className="control-panel-component ms-auto">

                        <DatePicker
                            className="form-control"
                            wrapperClassName="date-picker"
                            value={filters.dateFrom}
                            onChange={date => this.updateFilters({ dateFrom: date })}
                            placeholderText="Date (from)"
                        />

                        <DatePicker
                            className="form-control"
                            wrapperClassName="date-picker"
                            value={filters.dateTo}
                            onChange={date => this.updateFilters({ dateTo: date })}
                            placeholderText="Date (to)"
                        />

                    </div>
                }
                
            </section>

            <section>

                {this.renderTable()}

            </section>

            <EditApptModal
                ref={this.editApptModalRef}
                onSendCert={() => this.load()}
                {...this.props}
            />

        </>);
    }

    renderTable() {
        const {
            isLoading,
            appts,
            sortBy,
            sortDesc,
            page
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        
        const listParam = this.getListParam();
        const cols = {
            summary: {
                label: 'Summary',
                getValue: (colInfo, appt) => TextHelpers.getApptSummary(appt),
                sortable: true,
                sort: (a, b) => {
                    const getVal = (appt) => TextHelpers.getApptSummary(appt);
                    const valA = getVal(a);
                    const valB = getVal(b);
                    if (valA > valB) return 1;
                    else if (valA < valB) return -1;
                    else return 0;
                }
            },
            operativeName: {
                label: 'Operative',
                sortable: true
            },
            customerName: {
                label: 'Customer',
                getValue: (colInfo, appt) => {
                    if (appt.customer) {
                        return TextHelpers.formatName(appt.customer.title, appt.customer.firstName, appt.customer.lastName) || appt.customer.companyName;
                    }
                    return '';
                },
                sortable: true,
                sort: (a, b) => {
                    const getVal = (appt) => (appt.customer ? appt.customer.lastName + ', ' + appt.customer.firstName : '');
                    const valA = getVal(a);
                    const valB = getVal(b);
                    if (valA > valB) return 1;
                    else if (valA < valB) return -1;
                    else return 0;
                }
            },
            propertyName: {
                label: 'Property',
                getValue: (colInfo, appt) => {
                    if (appt.property) {
                        return appt.property.address.line1;
                    }
                    return '';
                },
                sortable: true,
                sort: (a, b) => {
                    const getVal = (appt) => (appt.property ? appt.property.address.postcode : 0);
                    const valA = getVal(a);
                    const valB = getVal(b);
                    if (valA > valB) return 1;
                    else if (valA < valB) return -1;
                    else return 0;
                }
            },  
            postcode: {
                label: 'Postcode',
                getValue: (colInfo, appt) => {
                    if (appt.property) {
                        return appt.property.address.postcode;
                    }
                    return '';
                },
                sortable: true,
                sort: (a, b) => {
                    const getVal = (appt) => (appt.property ? appt.property.address.postcode : '');
                    const valA = getVal(a);
                    const valB = getVal(b);
                    if (valA > valB) return 1;
                    else if (valA < valB) return -1;
                    else return 0;
                }
            },
            dateAndTime: {
                label: 'Date / Time',
                getValue: (colInfo, appt) => moment(appt.date).format('DD/MM/YYYY') + ' ' + appt.time,
                sortable: true,
                sort: (a, b) => {
                    const getVal = (appt) => parseInt(moment(appt.date).format('YYMMDD') + appt.time.replace(':', ''));
                    const valA = getVal(a);
                    const valB = getVal(b);
                    if (valA > valB) return 1;
                    else if (valA < valB) return -1;
                    else return 0;
                }
            },
            duration: {
                label: 'Duration',
                sortable: true
            },
            status: {
                label: 'Status',
                sortable: true
            }
        };
        
        if (listParam.isAwaitingCert) {
            cols.certAction = {
                label: 'Certificate action',
                getValue: (colInfo, appt) => {
                    const certAction = ReferenceHelpers.CertActions.find(ca => ca.id == appt.certAction);
                    return (certAction ? certAction.name : '');
                },
                sortable: true
            };
        }
        if (listParam.isAwaitingPayment) {
            cols.paymentType = {
                label: 'Payment type',
                getValue: (colInfo, appt) => {
                    const paymentType = ReferenceHelpers.PaymentTypes.find(pt => pt.id == appt.paymentType);
                    return (paymentType ? paymentType.name : '');
                },
                sortable: true
            };
            cols.balance = {
                label: 'Balance',
                type: 'currency',
                sortable: true
            };
        }
        
        cols.buttons = {
            label: '',
            className: 'buttons-col',
            getValue: (colInfo, appt) => <>
                <button type="button" className="btn btn-secondary btn-sm view-in-day-sheet-btn" onClick={e => {
                    e.stopPropagation();
                    const url = `/day-sheet/?date=${moment(appt.date).format('YYYY-MM-DD')}&apptID=${appt.id}`;
                    this.props.history.push(url);
                }}>
                    <span className="fa-solid fa-calendar-day" />{' '}Day sheet
                </button>

                {page == 'awaiting-payment' &&
                    <button type="button" className="btn btn-secondary btn-sm hide-btn ms-1" onClick={e => {
                        e.stopPropagation();
                        this.confirmHide(appt.id);
                    }}>
                        <span className="fa-solid fa-times" />{' '}Hide
                    </button>
                }
            </>
        };
        
        return (
            <SuperTable
                className="appts-table table table-bordered clickable mb-0"
                rows={appts}
                keyAccessor={appt => appt.id}
                cols={cols}
                sortBy={sortBy}
                sortDesc={sortDesc}
                onChangeSort={(sortBy, sortDesc) => this.setState({ sortBy, sortDesc })}
                onClick={(appt, e) => this.open(appt.id)}
                emptyText={page ? `No appointments` : 'Please select a page'}
            />
        );
    }
}

export default ListAppts;