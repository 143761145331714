import React from 'react';

// Services & Helpers
import API from 'API';
import debounce from 'lodash.debounce';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditPriceSchemeModal from 'components/setup/EditPriceSchemeModal';

//-----------------------------------------------------------------

class SetupPriceSchemes extends React.Component {

    constructor(props) {
        super(props);

        this.editPriceSchemeModalRef = React.createRef();

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Price Schemes setup');
        this.load();
    }

    async load() {
        const priceSchemes = await API.call('price-scheme/list');
        this.setState({
            isLoading: false,
            priceSchemes
        });
    }

    async open(id) {
        await this.editPriceSchemeModalRef.current.open({
            id
        });
        this.load();
    }

    async add() {
        await this.editPriceSchemeModalRef.current.open({
        });
        this.load();
    }
    
    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            priceSchemes
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel sticky">

                <h1>Price Schemes</h1>

                <button type="button" className="ms-auto btn btn-primary" onClick={() => this.add()}>
                    Add Price Scheme
                </button>

            </section>

            <section>

                <SuperTable
                    className="price-schemes-table table table-bordered clickable mb-0"
                    rows={priceSchemes}
                    keyAccessor={priceScheme => priceScheme.id}
                    cols={{
                        name: { label: 'Name' },
                        description: { label: 'Description' },
                        firstPrice: { label: '1st Price', type: 'currency' },
                        secondPlusPrice: { label: '2nd+ Price', type: 'currency' },
                        numProperties: { label: '# Properties' },
                        isDefault: { label: 'Default', type: 'boolean' }
                    }}
                    onClick={(priceScheme, e) => this.open(priceScheme.id)}
                    emptyText="No price schemes to show"
                />

            </section>

            <EditPriceSchemeModal
                ref={this.editPriceSchemeModalRef}
            />

        </>);
    }
    
}

export default SetupPriceSchemes;