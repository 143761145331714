import React from 'react';
import Creatable from 'react-select/creatable';

// Services & Helpers
import API from 'API';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Loader from 'components/common/Loader';
import EditCookerNotesModal from 'components/customer/EditCookerNotesModal';
import { CloseButton } from 'react-bootstrap';

//-----------------------------------------------------------------

class EditCookers extends React.Component {

    constructor(props) {
        super(props);

        this.editCookerNotesModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    load() {
        return new Promise(async (resolve) => {
            const cookerTypes = await API.call('cooker-type/list');
            this.setState({
                cookerTypes,
                isLoading: false
            }, resolve);
        });
    }

    addCookerType(cookerType) {
        return new Promise(async (resolve) => {
            // Add on server
            cookerType = { name: cookerType.label };
            cookerType.id = await API.call('cooker-type/save', cookerType);

            // Add to local list
            const cookerTypes = [...this.state.cookerTypes];
            cookerTypes.push(cookerType);
            this.setState({
                cookerTypes
            }, () => {
                resolve(cookerType.id);
            });
        });
    }

    async editNotes(cooker) {
        const notes = await this.editCookerNotesModalRef.current.open({
            notes: cooker.notes
        });
        this.props.updateFields(cooker, { notes });
    }

    confirmRemove(cooker) {
        const confirm = window.confirm('Are you sure you want to remove this cooker?');
        if (confirm) {
            this.props.updateFields(cooker, { isDeleted: true });
        }
    }

    startDedupe() {
        this.setState({
            isDeduping: true,
            selectedCookerIDs: {},
            primaryCookerID: null
        });
    }
    
    cancelDedupe() {
        this.setState({
            isDeduping: false
        });
    }

    toggleCooker(id) {
        const selectedCookerIDs = { ...this.state.selectedCookerIDs };
        let { primaryCookerID } = this.state;
        if (!primaryCookerID) primaryCookerID = id;
        if (selectedCookerIDs[id]) {
            delete selectedCookerIDs[id];
            if (id == primaryCookerID) {
                const otherIDs = Object.keys(selectedCookerIDs);
                primaryCookerID = (otherIDs.length > 0 ? otherIDs[0] : null);
            }
        }
        else selectedCookerIDs[id] = true;
        this.setState({ selectedCookerIDs, primaryCookerID });
    }
    
    async confirmDedupe() {
        const confirm = window.confirm('Are you sure you want to de-duplicate these cookers?');
        if (confirm) {
            this.setState({ isLoading: true });
            const { primaryCookerID, selectedCookerIDs } = this.state;
            await API.call('dedupe/run-cookers', {
                primaryCookerID,
                selectedCookerIDs: Object.keys(selectedCookerIDs)
            });
            this.cancelDedupe();
            this.props.onDedupe();
        }
    }
    
    //------------------------------------------------------------------------------------------------------

    render() {
        const { isLoading, cookerTypes, isDeduping, selectedCookerIDs } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        // Build cooker type options
        let cookerTypeOptions = [
            { label: '(Unspecified)', value: '' }
        ];
        cookerTypeOptions = cookerTypeOptions.concat(cookerTypes.map(ct => ({
            value: ct.id,
            label: (ct.code ? ct.code + ' - ' : '') + ct.name
        })));

        // Get list of cookers with 'new' at the end
        const cookers = [...this.props.cookers.filter(c => !c.isDeleted)];
        if (!isDeduping) {
            cookers.push({ isNew: true });
        }

        return (<>

            <h2 className="mt-4">
                Cookers ({cookers.length - 1})

                {!isDeduping ?
                    <button type="button" className="btn btn-secondary ms-auto" onClick={() => this.startDedupe()}>
                        <span className="fa-solid fa-copy" />{' '}
                        De-duplicate
                    </button> : <>
                        <button type="button" className="btn btn-secondary ms-auto" onClick={() => this.cancelDedupe()}>
                            <span className="fa-solid fa-times" />{' '}
                            Cancel
                        </button>
                        <button type="button" className="btn btn-secondary ms-3" onClick={() => this.confirmDedupe()} disabled={Object.keys(selectedCookerIDs).length < 2}>
                            <span className="fa-solid fa-check" />{' '}
                            Confirm
                        </button>
                </>}
            </h2>

            <table className="cust-cookers-table table table-bordered">
                <thead>
                    <tr>
                        {isDeduping &&
                            <th className="select-col"></th>
                        }
                        <th className="type-col">Type</th>
                        <th className="floor-col">Floor</th>
                        <th className="location-col">Location</th>
                        <th className="is-in-use-col">In use</th>
                        <th className="notes-col"></th>
                    </tr>
                </thead>
                <tbody>
                    {cookers.map(cooker => {
                        // Set up select options
                        let floorOptions = [
                            { label: '(Unspecified)', value: '' }
                        ];
                        floorOptions = floorOptions.concat(ReferenceHelpers.Floors);
                        if (!floorOptions.find(f => cooker.floor == f.value)) {
                            floorOptions.push({ value: cooker.floor, label: cooker.floor });
                        }

                        let locationOptions = [
                            { label: '(Unspecified)', value: '' }
                        ];
                        locationOptions = locationOptions.concat(ReferenceHelpers.Locations);
                        if (!locationOptions.find(f => cooker.location == f.value)) {
                            locationOptions.push({ value: cooker.location, label: cooker.location });
                        }

                        return (
                            <tr key={cooker.guid || Math.random()}>
                                {isDeduping && !!cooker.id &&
                                    <td className="select-col">
                                        <input
                                            type="checkbox"
                                            checked={selectedCookerIDs[cooker.id] || ''}
                                            onChange={e => this.toggleCooker(cooker.id)}
                                        />
                                    </td>
                                }
                                <td className="type-col">

                                    <Creatable
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        isClearable={false}
                                        options={cookerTypeOptions}
                                        value={cookerTypeOptions.find(ct => ct.value == cooker.cookerTypeID)}
                                        formatCreateLabel={name => <>Add <b>{name}</b></>}
                                        isDisabled={isDeduping}
                                        onChange={async cookerType => {
                                            if (cookerType.__isNew__) {
                                                const id = await this.addCookerType(cookerType);
                                                this.props.updateFields(cooker, {
                                                    cookerTypeID: id
                                                });
                                            } else {
                                                this.props.updateFields(cooker, {
                                                    cookerTypeID: cookerType.value
                                                });
                                            }
                                        }}
                                    />
                                </td>
                                <td className="floor-col">

                                    {!cooker.isNew &&
                                        <Creatable
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            isClearable={false}
                                            options={floorOptions}
                                            value={floorOptions.find(f => f.label == cooker.floor)}
                                            formatCreateLabel={name => <>Add <b>{name}</b></>}
                                            isDisabled={isDeduping}
                                            onChange={async floor => {
                                                this.props.updateFields(cooker, {
                                                    floor: floor.label
                                                });
                                            }}
                                        />
                                    }

                                </td>
                                <td className="location-col">

                                    {!cooker.isNew &&
                                        <Creatable
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            isClearable={false}
                                            options={locationOptions}
                                            value={locationOptions.find(f => f.label == cooker.location)}
                                            formatCreateLabel={name => <>Add <b>{name}</b></>}
                                            isDisabled={isDeduping}
                                            onChange={async location => {
                                                this.props.updateFields(cooker, {
                                                    location: location.label
                                                });
                                            }}
                                        />
                                    }

                                </td>

                                <td className="is-in-use-col">

                                    {!cooker.isNew &&
                                        <input
                                            type="checkbox"
                                            checked={cooker.isInUse || false}
                                            onChange={e => this.props.updateFields(cooker, { isInUse: e.target.checked })}
                                        />
                                    }

                                </td>

                                <td className="notes-col floating-controls">
                                    <div>

                                        {!cooker.isNew && <>

                                            <button type="button" className="btn btn-primary" title={cooker.notes} onClick={() => this.editNotes(cooker)}>
                                                <span className={`${cooker.notes ? 'fa-solid' : 'fa-regular'} fa-comment`} />
                                            </button>

                                            {!isDeduping &&
                                                <div className="btns">

                                                    <button type="button" className="btn btn-danger" onClick={() => this.confirmRemove(cooker)}>
                                                        <span className="fa fa-times" />
                                                    </button>

                                                </div>
                                            }

                                        </>}

                                    </div>
                                </td>

                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <EditCookerNotesModal
                ref={this.editCookerNotesModalRef}
            />

        </>);
    }

}

export default EditCookers;
