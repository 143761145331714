import React from 'react';
import { Route } from 'react-router-dom';

// Components
import PageList from 'components/common/PageList';
import SetupAccount from 'components/setup/SetupAccount';
import SetupUsers from 'components/setup/SetupUsers';
import SetupPriceSchemes from 'components/setup/SetupPriceSchemes';
import SetupServiceTypes from 'components/setup/SetupServiceTypes';
import SetupCustomerSources from 'components/setup/SetupCustomerSources';
import SetupNotifications from 'components/setup/SetupNotifications';
import SetupBooking from 'components/setup/SetupBooking';
import SetupProducts from 'components/setup/SetupProducts';
import SetupThirdParty from 'components/setup/SetupThirdParty';

//-----------------------------------------------------------------

class Setup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.pages = [
            { id: 'account', label: 'Company' }, 
            { id: 'users', label: 'Users & Operatives' }, 
            { id: 'service-types', label: 'Services' }, 
            { id: 'price-schemes', label: 'Price Schemes' }, 
            { id: 'customer-sources', label: 'Sources' }, 
            { id: 'notifications', label: 'Notifications' },
            { id: 'booking', label: 'Booking' },
            { id: 'products', label: 'Products' },
            { id: 'third-party', label: 'Connected software' }
        ];
    }

    componentDidMount() {
        this.props.fns.setTitle('Setup');
    }
    
    //----------------------------------------------------------------------

    render() {
        const { page } = this.props;

        return (<>

            <section className="control-panel">

                <PageList
                    pages={this.pages}
                    page={page}
                    getURL={(id) => `/setup/${id}`}
                />

            </section>

            <Route path="/setup/account" render={() => <SetupAccount {...this.props} />} />
            <Route path="/setup/users" render={() => <SetupUsers {...this.props} />} />
            <Route path="/setup/service-types" render={() => <SetupServiceTypes {...this.props} />} />
            <Route path="/setup/price-schemes" render={() => <SetupPriceSchemes {...this.props} />} />
            <Route path="/setup/customer-sources" render={() => <SetupCustomerSources {...this.props} />} />
            <Route path="/setup/notifications" render={() => <SetupNotifications {...this.props} />} />
            <Route path="/setup/booking" render={() => <SetupBooking {...this.props} />} />
            <Route path="/setup/products" render={() => <SetupProducts {...this.props} />} />
            <Route path="/setup/third-party" render={() => <SetupThirdParty {...this.props} />} />
            
        </>);
    }

}

export default Setup;