import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class SetupThirdParty extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isConnected: {}
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Connected software');
        this.load();
    }

    async load() {
        const mailchimp = await API.call('mailchimp/get-is-connected');
        // TODO uncomment this when enabling QuickBooks
        //const quickbooks = await API.call('quickbooks/get-is-connected');
        this.setState({
            isLoading: false,
            isConnected: {
                mailchimp,
                //quickbooks
            }
        });
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            isConnected
        } = this.state;
        const { accountID } = this.props.loginInfo;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel sticky">

                <h1>Connected Services</h1>

            </section>

            <section>

                <h2>Mailchimp</h2>

                {isConnected.mailchimp ?
                    <p>Connected</p> :
                    <a className="btn btn-primary" href={`/api/mailchimp/connect/${accountID}`}>
                        Connect
                    </a>
                }

                {/*            
                    // TODO uncomment this when enabling QuickBooks
                <h2>Quickbooks</h2>

                {isConnected.quickbooks ?
                    <p>Connected</p> :
                    <a className="btn btn-primary" href={`/api/quickbooks/connect/${accountID}`}>
                        Connect
                    </a>
                }
                */}

            </section>

        </>);
    }

}

export default SetupThirdParty;