import React from 'react';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class SetupNotifications extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.form = new FormHelper({
            fields: {
                emailHost: {
                    label: 'Host'
                },
                emailPort: {
                    label: 'Port',
                    type: 'number'
                },
                emailUsername: {
                    label: 'Username'
                },
                emailPassword: {
                    label: 'App Password (16 digits long, must be generated by IT provider. DON\'T CHANGE UNLESS IT PROVIDER TELLS YOU TO!)',
                    type: 'password'
                },
                copyEmails: {
                    boxLabel: 'Copy emails to sender',
                    type: 'checkbox'
                },
                smsNumber: {
                    label: 'SMS Number',
                    type: 'text'
                }
            },
            getValue: (fieldName) => this.state.account[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
        
    }

    componentDidMount() {
        this.props.fns.setTitle('Notifications setup');
        this.load();
    }

    async load() {
        const account = await API.call('account/get-current');
        this.setState({
            isLoading: false,
            account
        });
    }

    async save() {
        const { account } = this.state;
        this.setState({ isLoading: true });
        await API.call('account/save-current/notifications', account);
        this.props.history.replace('/setup');
    }

    updateFields(fields, callback) {
        const account = { ...this.state.account };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            account[fieldName] = value;
        }
        this.setState({ account }, callback);
    }

    updateNotification(id, fields) {
        const notifications = [...this.state.account.notifications];
        const index = notifications.findIndex(n => n.id == id);
        const notification = { ...notifications[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            notification[fieldName] = value;
        }
        notifications[index] = notification;
        this.updateFields({ notifications });
    }

    addNotification() {
        const notifications = [...this.state.account.notifications];
        let lowestID = 0;
        notifications.forEach(n => {
            if (n.id < lowestID) {
                lowestID = n.id;
            }
        });
        lowestID--;
        notifications.push({
            id: lowestID
        });
        this.updateFields({ notifications });
    }

    removeNotification(id) {
        const confirm = window.confirm('Remove this notification?')
        if (confirm) {
            const notifications = [...this.state.account.notifications];
            const index = notifications.findIndex(n => n.id == id);
            notifications.splice(index, 1);
            this.updateFields({ notifications });
        }
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            account
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.save() }}>

                <section className="control-panel sticky">

                    <h1>Notifications</h1>

                    <button type="submit" className="ms-auto btn btn-primary">
                        Save
                    </button>

                </section>

                {this.renderNotificationsSection()}

                <div className="row">

                    <div className="col-md-6">

                        {this.renderEmailsSection()}

                    </div>

                    <div className="col-md-6">
                        
                        {this.renderSMSSection()}

                    </div>

                </div>

            </form>

        </>);
    }

    renderNotificationsSection() {
        const { account } = this.state;

        const table = (

            <table className="table table-bordered notifications-table mb-0">
                <thead>
                    <tr>
                        <th className="event-col">When...</th>
                        <th className="send-sms-col">Send SMS</th>
                        <th className="send-email-col">Send Email</th>
                        {/*<th className="num-days-col">Days before/after</th>*/}
                        <th className="options-col">Options</th>
                    </tr>
                </thead>
                <tbody>
                    {account.notifications.map(notification =>
                        <tr key={notification.id}>
                            <td className="event-col">
                                <select
                                    className="form-control"
                                    value={notification.type || ''}
                                    onChange={e => this.updateNotification(notification.id, { type: e.target.value })}
                                >
                                    <option value="">(Select)</option>
                                    <option value="ApptBooked">An appointment is booked</option>
                                    <option value="ApptReminder">An appointment is upcoming</option>
                                    <option value="ApptCompleted">An appointment is completed</option>
                                </select>
                            </td>
                            <td className="send-sms-col">
                                {!!notification.type &&
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        checked={notification.sendSMS || false}
                                        onChange={e => this.updateNotification(notification.id, { sendSMS: e.target.checked })}
                                    />
                                }
                            </td>
                            <td className="send-email-col">
                                {!!notification.type &&
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        checked={notification.sendEmail || false}
                                        onChange={e => this.updateNotification(notification.id, { sendEmail: e.target.checked })}
                                    />
                                }
                            </td>
                            <td className="options-col floating-controls">
                                {!!notification.type &&
                                    <div>

                                        {notification.type == 'ApptReminder' &&
                                            <div>
                                                Send
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={notification.numDays || ''}
                                                    onChange={e => this.updateNotification(notification.id, { numDays: e.target.value })}
                                                />
                                                day(s) before appointment
                                            </div>
                                        }
                                        <div>
                                            Only if the appointment has at least
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={notification.minCookers || ''}
                                                onChange={e => this.updateNotification(notification.id, { minCookers: e.target.value })}
                                            />
                                            cooker(s)
                                        </div>
                                        <div>
                                            Only if the appointment was booked at least
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={notification.minDaysAhead || ''}
                                                onChange={e => this.updateNotification(notification.id, { minDaysAhead: e.target.value })}
                                            />
                                            day(s) into the future
                                        </div>

                                        {notification.sendEmail && <>
                                            <div>
                                                CC addresses:
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={notification.ccAddresses || ''}
                                                    onChange={e => this.updateNotification(notification.id, { ccAddresses: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                BCC addresses:
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={notification.bccAddresses || ''}
                                                    onChange={e => this.updateNotification(notification.id, { bccAddresses: e.target.value })}
                                                />
                                            </div>
                                        </>}

                                        <div className="btns">

                                            <button type="button" className="btn btn-danger" onClick={() => this.removeNotification(notification.id)}>
                                                <span className="fa fa-times" />
                                            </button>

                                        </div>

                                    </div>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );

        return (
            <section className="notifications-section">

                {account.notifications.length > 0 ? table :
                    <div className="empty-text">No notifications have been set up</div>
                }

                <p className="mb-0">
                    <button type="button" className="btn btn-primary" onClick={() => this.addNotification()}>
                        Add Notification
                    </button>
                </p>

            </section>
        );
    }

    renderEmailsSection() {
        return (
            <section>

                <h2>Email Settings</h2>

                <fieldset>

                    {this.form.render([
                        'emailUsername',
                        'emailPassword',
                        'copyEmails'
                    ])}

                </fieldset>

            </section>
        );
    }

    renderSMSSection() {
        const { account } = this.state;
        return (
            <section>

                <h2>SMS Settings</h2>

                <fieldset>

                    {this.form.render([
                        'smsNumber'
                    ])}

                </fieldset>

            </section>
        );
    }

}

export default SetupNotifications;