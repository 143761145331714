import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditCustomerSourceModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                name: {
                    label: 'Name',
                    type: 'text',
                    autoFocus: true
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: null,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const {
            id
        } = this.state;

        // Load customer source
        let customerSource;
        if (id) {
            customerSource = await API.call('customer-source/get/' + id);
        } else {
            customerSource = {
            };
        }

        // Update
        this.setState({
            customerSource,
            isLoading: false
        });
    }
    
    getFieldValue(fieldName) {
        return this.state.customerSource[fieldName];
    }

    updateFields(fields) {
        const customerSource = { ...this.state.customerSource };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            customerSource[fieldName] = value;
        }
        this.setState({ customerSource });
    }

    async saveAndClose() {
        this.setState({ isLoading: true });
        await API.call('customer-source/save', this.state.customerSource);
        this.close();
        this.resolve();
    }

    async confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this customer source?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('customer-source/delete/' + this.state.id);
            this.close();
            this.resolve();
        }
    }

    render() {
        const { 
            isOpen,
            isLoading,
            id
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-customer-source-modal" backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.saveAndClose(); e.preventDefault() }}>
                        {/*
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        */}
                        <Modal.Body>

                            {this.renderInner()}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                Cancel
                            </button>
                            {!!id &&
                                <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                                    Delete
                                </button>
                            }
                            <button type="submit" className="btn btn-primary ms-auto">
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </form>
                }
            </Modal>
        );
    }

    renderInner() {
        return (<>

            <fieldset>

                {this.form.render([
                    'name'
                ])}

            </fieldset>

        </>);
    }
}

export default EditCustomerSourceModal;