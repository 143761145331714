// Libs
import React from 'react';

//-------------------------------------------------------------------------------------------------------------------

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { }
    }
    
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    async componentDidCatch(error, errorInfo) {
        //await BaseService.callAPI('debug/log-client-error', {
        //    error: error.toString(),
        //    stackTrace: errorInfo.componentStack,
        //    url: window.location.href
        //});
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    
                    <h1>Sorry, there's been a problem</h1>
                    <h2>Please contact support</h2>

                </div>
            );
        }

        return this.props.children;
    }

}

export default ErrorBoundary;