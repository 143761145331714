import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ExpandingTextArea from 'react-expanding-textarea';
import API from 'API';

import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class DoNotCleanModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doNotClean: false,
            reason: null
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                customerID: opt.customerID,
                doNotClean: !!opt.reason,
                reason: opt.reason
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async saveAndClose() {
        const { customerID, doNotClean, reason } = this.state;

        if (doNotClean && !reason) {
            alert('If \'Do Not Clean\' is ticked, you must provide a reason');
            return;
        }

        this.setState({ isLoading: true });
        await API.call('customer/set-do-not-clean', {
            customerID,
            doNotClean,
            reason
        });
        this.close();
        this.resolve();
    }

    render() {
        const { 
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="do-not-clean-modal" backdrop="static">
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Body>
                        
                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary ms-auto">
                            Save Changes
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const { isLoading, doNotClean, reason } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <label className="checkbox-label">
                <input
                    type="checkbox"
                    checked={doNotClean}
                    onChange={e => this.setState({ doNotClean: e.target.checked })}
                />{' '}
                Do Not Clean
            </label>

            {doNotClean &&
                <div className="form-group mt-3">
                    <label className="form-label">Reason</label>
                    <ExpandingTextArea
                        className="form-control"
                        autoFocus
                        value={reason || ''}
                        onChange={e => this.setState({ reason: e.target.value })}
                        rows={3}
                        onFocus={e => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                    />
                </div>
            }

        </>);
    }
}

export default DoNotCleanModal;