import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { NumericFormat } from 'react-number-format';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditPriceSchemeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                isDefault: {
                  label: 'Default',
                  type: 'checkbox',
                },
                name: {
                    label: 'Name',
                    type: 'text',
                    autoFocus: true
                },
                description: {
                    label: 'Description',
                    type: 'expanding-text',
                    rows: 2
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: null,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const {
            id
        } = this.state;

        // Load price scheme
        let priceScheme;
        if (id) {
            priceScheme = await API.call('price-scheme/get/' + id);
        } else {
            priceScheme = {
            };
        }

        // Load service types
        const serviceTypes = await API.call('service-type/list', {
            priceSchemeID: (id || null)
        });

        // Update
        this.setState({
            priceScheme,
            serviceTypes,
            isLoading: false
        });
    }
    
    getFieldValue(fieldName) {
        return this.state.priceScheme[fieldName];
    }

    updateFields(fields) {
        const priceScheme = { ...this.state.priceScheme };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            priceScheme[fieldName] = value;
        }
        this.setState({ priceScheme });
    }

    updateServiceTypeFields(serviceTypeID, fields) {
        const serviceTypes = [...this.state.serviceTypes];
        const index = serviceTypes.findIndex(st => st.id == serviceTypeID);
        const serviceType = { ...serviceTypes[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            serviceType[fieldName] = value;
        }
        serviceTypes[index] = serviceType;
        this.setState({ serviceTypes });
    }

    async saveAndClose() {
        this.setState({ isLoading: true });
        const priceScheme = {
            ...this.state.priceScheme,
            serviceTypes: this.state.serviceTypes.map(st => ({
                serviceTypeID: st.id,
                firstPrice: st.firstPrice,
                secondPlusPrice: st.secondPlusPrice
            }))
        };
        await API.call('price-scheme/save', priceScheme);
        this.close();
        this.resolve();
    }

    async confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this price scheme?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('price-scheme/delete/' + this.state.id);
            this.close();
            this.resolve();
        }
    }

    render() {
        const { 
            isOpen,
            isLoading,
            id
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-price-scheme-modal" backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.saveAndClose(); e.preventDefault() }}>
                        {/*
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        */}
                        <Modal.Body>

                            {this.renderInner()}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                Cancel
                            </button>
                            {!!id &&
                                <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                                    Delete
                                </button>
                            }
                            <button type="submit" className="btn btn-primary ms-auto">
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </form>
                }
            </Modal>
        );
    }

    renderInner() {
        const { serviceTypes } = this.state;

        return (<>

            <fieldset>

                {this.form.render([
                    'isDefault',
                    'name',
                    'description'
                ])}

                <table className="table table-bordered price-scheme-service-types-table">
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th className="price-col">1st Price</th>
                            <th className="price-col">2nd+ Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {serviceTypes.map(serviceType =>
                            <tr key={serviceType.id}>
                                <td>{serviceType.name}</td>
                                <td className="price-col">
                                    <NumericFormat
                                        className="form-control"
                                        thousandSeparator={','}
                                        decimalSeparator={'.'}
                                        prefix="£"
                                        value={serviceType.firstPrice || ''}
                                        onValueChange={(values) => {
                                            const value = Number(values.value) || '';
                                            this.updateServiceTypeFields(serviceType.id, { firstPrice: value });
                                        }}
                                    />
                                </td>
                                <td className="price-col">
                                    <NumericFormat
                                        className="form-control"
                                        thousandSeparator={','}
                                        decimalSeparator={'.'}
                                        prefix="£"
                                        value={serviceType.secondPlusPrice || ''}
                                        onValueChange={(values) => {
                                            const value = Number(values.value) || '';
                                            this.updateServiceTypeFields(serviceType.id, { secondPlusPrice: value });
                                        }}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </fieldset>

        </>);
    }
}

export default EditPriceSchemeModal;