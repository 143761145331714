import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditProductModal from 'components/setup/EditProductModal';

//-----------------------------------------------------------------

class SetupProducts extends React.Component {

    constructor(props) {
        super(props);

        this.editProductModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Products setup');
        this.load();
    }

    async load() {
        const products = await API.call('product/list');
        this.setState({
            isLoading: false,
            products
        });
    }

    async open(id) {
        await this.editProductModalRef.current.open({
            id
        });
        this.load();
    }

    async add() {
        await this.editProductModalRef.current.open({
        });
        this.load();
    }
    
    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            products
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel sticky">

                <h1>Products</h1>

                <button type="button" className="ms-auto btn btn-primary" onClick={() => this.add()}>
                    Add Product
                </button>

            </section>

            <section>

                <SuperTable
                    className="products-table table table-bordered clickable mb-0"
                    rows={products}
                    keyAccessor={product => product.id}
                    cols={{
                        category: {
                            label: 'Category'
                        },
                        name: {
                            label: 'Name'
                        },
                        isSold: {
                            label: 'Is sold',
                            getValue: (colInfo, product) => product.isSold && <span className="fa-solid fa-check" />
                        },
                        price: {
                            label: 'Price',
                            type: 'currency'
                        },
                        // TODO uncomment this when enabling QuickBooks
                        //isLinkedToQuickbooks: {
                        //    label: 'Linked to Quickbooks',
                        //    getValue: (colInfo, product) => !!product.qbItemID &&
                        //        <span className="fa-solid fa-check" />
                        //}
                    }}
                    onClick={(product, e) => this.open(product.id)}
                    emptyText="No products to show"
                />

            </section>

            <EditProductModal
                ref={this.editProductModalRef}
            />

        </>);
    }
    
}

export default SetupProducts;