import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Services
import App from 'App';
import API from 'API';

// Components
import PriceCalcModal from 'components/appt/PriceCalcModal';
import SelectAccountModal from 'components/layout/SelectAccountModal';

// Images
import logoImage from 'images/logo.png';

//-------------------------------------------------------------------------------------------------------------------

class SideNav extends Component {

    constructor(props) {
        super(props);

        this.priceCalModalRef = React.createRef();
        this.selectAccountModalRef = React.createRef();
    }

    componentDidMount() {
        const { loginInfo } = this.props;
        if (loginInfo.accountID == loginInfo.user.bookingAgentAccountID || loginInfo.accountID == loginInfo.user.headOfficeAccountID) {
            this.showSelectAccountModal();
        }
    }

    showPriceCalcModal() {
        this.priceCalModalRef.current.open({});
    }

    showSelectAccountModal() {
        this.selectAccountModalRef.current.open({});
    }

    async confirmLogOut() {
        const confirm = window.confirm('Are you sure you want to log out?');
        if (confirm) {
            await API.call('account/log-out');
            window.location = '/';
        }
    }

    render() {
        const {
            loginInfo
        } = this.props;

        return (<>
            <aside className="side-nav">

                <img src={logoImage} className="logo" />

                <ul>
                    {loginInfo.account.isTrialMode &&
                        <li>
                            <a href="#" onClick={e => { e.preventDefault(); }} style={{ color: 'red' }}>
                                <span className="icon fa-solid fa-triangle-exclamation" />
                                <span className="nav-label">
                                    Trial account; email and SMS have been disabled
                                </span>
                            </a>
                        </li>
                    }
                    {loginInfo.accounts.length > 1 &&
                        <li>
                            <a href="#" onClick={e => { e.preventDefault(); this.showSelectAccountModal(); }}>
                                <span className="icon fa-solid fa-arrows-rotate" />
                                <span className="nav-label">
                                    {loginInfo.account.accountName}
                                </span>
                            </a>
                        </li>
                    }
                    <li className={this.getNavClass(['/day-sheet'])}>
                        <Link to="/day-sheet">
                            <span className="icon fa-regular fa-calendar" />
                            <span className="nav-label">
                                Day Sheet
                            </span>
                        </Link>
                    </li>
                    <li className={this.getNavClass([ '/appts', '/appt' ])}>
                        <Link to="/appts">
                            <span className="icon fa-solid fa-list" />
                            <span className="nav-label">
                                Appointments
                            </span>
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/customer', '/customers'], [ '/customer-' ])}>
                        <Link to="/customers/active">
                            <span className="icon fa-solid fa-house-chimney" />
                            <span className="nav-label">
                                Customers
                            </span>
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/rebook', '/rebooks'])}>
                        <Link to="/rebooks">
                            <span className="icon fa-solid fa-phone" />
                            <span className="nav-label">
                                Rebooks
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={e => {
                            e.preventDefault();
                            this.showPriceCalcModal();
                        }}> 
                            <span className="icon fa-regular fa-clipboard" />
                            <span className="nav-label">
                                Quotation
                            </span>
                        </Link>
                    </li>
                    {/*
                    <li className={this.getNavClass(['/marketing'])}>
                        <Link to="/marketing">
                            <span className="icon fa-solid fa-bullhorn" />
                            Marketing
                        </Link>
                    </li>
                    */}
                    <li className={this.getNavClass(['/banking'])}>
                        <Link to="/banking">
                            <span className="icon fa-solid fa-coins" />
                            <span className="nav-label">
                                Banking
                            </span>
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/reports', '/report'])}>
                        <Link to="/reports">
                            <span className="icon fa-solid fa-chart-line" />
                            <span className="nav-label">
                                Reports
                            </span>
                        </Link>
                    </li>
                    <li className={'bottom ' + this.getNavClass(['/customer-comms'])}>
                        <Link to="/customer-comms">
                            <span className="icon fa-solid fa-envelope-circle-check" />
                            <span className="nav-label">
                                Email / SMS
                            </span>
                        </Link>
                    </li>
                    {(!!loginInfo.user.headOfficeAccountID || loginInfo.account.isOwner || loginInfo.account.isBookingAgent) &&
                        <li className={this.getNavClass(['/setup'])}>
                            <Link to="/setup">
                                <span className="icon fa-solid fa-cog" />
                                <span className="nav-label">
                                    Setup (Version: {App.VERSION})
                                </span>
                            </Link>
                        </li>
                    }
                    {!!loginInfo.user.headOfficeAccountID &&
                        <li className={this.getNavClass(['/head-office/accounts'])}>
                            <Link to="/head-office/accounts">
                                <span className="icon fa-solid fa-users" />
                                <span className="nav-label">
                                    Accounts
                                </span>
                            </Link>
                        </li>
                    }
                    <li>
                        <Link to="/" onClick={e => {
                            e.preventDefault();
                            this.confirmLogOut();
                        }}>
                            <span className="icon fa-solid fa-right-from-bracket" />
                            <span className="nav-label">
                                Log out
                            </span>
                        </Link>
                    </li>
                </ul>

            </aside>

            <PriceCalcModal
                ref={this.priceCalModalRef}
            />

            <SelectAccountModal
                ref={this.selectAccountModalRef}
                loginInfo={loginInfo}
            />

        </>);
    }
    
    getNavClass(paths, dontMatchPaths) {
        const { pathname } = this.props.location;
        if (paths.some(p => pathname.indexOf(p) == 0) &&
            (!dontMatchPaths || !dontMatchPaths.some(p => pathname.indexOf(p) == 0))
        ) {
            return 'active';
        }
        return '';
    }

}

export default withRouter(SideNav);
