import React from 'react';
import Modal from 'react-bootstrap/Modal';
import queryString from 'query-string';

// Services
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class ResendEmailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: opt.id
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.resolve(false);
        this.setState({ isOpen: false });
    }

    async load() {
        const customerComm = await API.call('customer-comm/get/' + this.state.id);
        this.setState({
            customerComm,
            isLoading: false
        });
    }

    async send() {
        //this.resolve(true);
        //this.setState({ isOpen: false });
    }

    updateFields(values) {
        const { customerComm } = this.state;
        for (let fieldName in values) {
            const value = values[fieldName];
            customerComm[fieldName] = value;
        }
        this.setState({ customerComm });
    }

    async resend() {
        const { customerComm } = this.state;
        this.setState({ isLoading: true });
        await API.call('customer-comm/resend', {
            customerCommID: customerComm.id,
            emailAddress: customerComm.recipient
        });
        this.close();
        this.resolve();
    }

    //--------------------------------------------------------------------------

    render() {
        const { 
            isOpen,
            customerComm
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="resend-email-modal" backdrop="static" keyboard={false}>
                <Modal.Body>
                    
                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary me-auto" onClick={() => this.close()}>
                        Cancel
                    </button>
                    {customerComm && !!customerComm.detailedError &&
                        <button type="button" className="btn btn-primary ms-auto" onClick={() => this.resend()}>
                            Resend
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const { isLoading, customerComm } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="row">

                <div className="col-md-4">

                    <div className="form-group">
                        <label className="form-label">Recipient</label>
                        <input
                            type="email"
                            className="form-control"
                            value={customerComm.recipient || ''}
                            onChange={e => this.updateFields({ recipient: e.target.value })}
                        />
                    </div>

                    {!!customerComm.detailedError &&
                        <div className="form-group">
                            <label className="form-label">Error details</label>
                            <textarea
                                className="form-control"
                                value={customerComm.detailedError}
                                disabled={true}
                                style={{ height: 400 }}
                            />
                        </div>
                    }
                </div>

                <div className="col-md-8">

                    <iframe srcDoc={customerComm.body} sandbox="allow-same-origin" />

                </div>

            </div>

        </>);
    }
}

export default ResendEmailModal;