import React from 'react';
import { Link } from 'react-router-dom';

class PageList extends React.Component {

    render() {
        const {
            pages,
            getURL,
            onClick
        } = this.props;
        const page = decodeURIComponent(this.props.page);
        
        if (pages.length > 0) {
            return (
                <ul className="nav nav-pills">
                    {pages.map(p =>
                        <li key={p.id || 'none'} className="nav-item" onClick={onClick ? () => onClick(p.id) : null}>
                            <Link to={getURL(p.id == '#' ? '%23' : p.id)} className={`nav-link ${(p.id == page ? 'active' : '')}`}>
                                {p.label}
                            </Link>
                        </li>
                    )}
                </ul>
            );
        }
        
        return null;
    }

}

export default PageList;