import clsx from 'clsx'
import React from 'react'

function stringifyPercent(v) {
    return typeof v === 'string' ? v : v + '%'
}

/* eslint-disable react/prop-types */
function TimeGridEvent(props) {
    const {
        style,
        className,
        event,
        accessors,
        rtl,
        selected,
        label,
        continuesEarlier,
        continuesLater,
        getters,
        onClick,
        onDoubleClick,
        onKeyPress,
        components: { event: Event, eventWrapper: EventWrapper },
    } = props;
    let isBackgroundEvent = (event.isBackgroundEvent || props.isBackgroundEvent);
    let title = accessors.title(event)
    let tooltip = accessors.tooltip(event)
    let end = accessors.end(event)
    let start = accessors.start(event)

    let userProps = getters.eventProp(event, start, end, selected)

    let { height, top, width, xOffset } = style
    const inner = [
        <div key="1" className="rbc-event-label">
            {label}
        </div>,
        <div key="2" className="rbc-event-content">
            {Event ? <Event event={event} title={title} /> : title}
        </div>,
    ]

    // KJA - allow any event to be a background event
    const eventStyle = isBackgroundEvent
        ? {
            // KJA - background events don't overlap others
            ...userProps.style,
            top: stringifyPercent(top),
            height: stringifyPercent(height),
            width: '100%'
        }
        : {
            ...userProps.style,
            top: stringifyPercent(top),
            width: stringifyPercent(width),
            height: stringifyPercent(height),
            [rtl ? 'right' : 'left']: stringifyPercent(xOffset),
        }

    if (!isBackgroundEvent) {
        //debugger;
    }

    return (
        <EventWrapper type="time" {...props}>
            <div
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                style={eventStyle}
                onKeyPress={onKeyPress}
                title={
                    tooltip
                        ? (typeof label === 'string' ? label + ': ' : '') + tooltip
                        : undefined
                }
                className={clsx(
                    isBackgroundEvent ? 'rbc-background-event' : 'rbc-event',
                    className,
                    userProps.className,
                    {
                        'rbc-selected': selected,
                        'rbc-event-continues-earlier': continuesEarlier,
                        'rbc-event-continues-later': continuesLater,
                    }
                )}
            >
                {inner}
            </div>
        </EventWrapper>
    )
}

export default TimeGridEvent
